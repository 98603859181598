import React from 'react';
import { ChevronDown } from 'lucide-react';

interface DifficultyFilterProps {
  difficulties: { id: string; name: string }[];
  selectedDifficulty: string;
  onDifficultyChange: (difficulty: string) => void;
}

const DifficultyFilter: React.FC<DifficultyFilterProps> = ({ difficulties, selectedDifficulty, onDifficultyChange }) => {
  return (
    <div className="relative inline-block w-full">
      <select
        value={selectedDifficulty}
        onChange={(e) => onDifficultyChange(e.target.value)}
        className="appearance-none w-full bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-700 rounded-lg py-2 px-4 pr-8 leading-tight focus:outline-none focus:border-blue-500 dark:focus:border-blue-400 transition-colors duration-200"
      >
        {difficulties.map((difficulty) => (
          <option key={difficulty.id} value={difficulty.id}>
            {difficulty.name}
          </option>
        ))}
      </select>
      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 dark:text-gray-300">
        <ChevronDown className="h-4 w-4" />
      </div>
    </div>
  );
};

export default DifficultyFilter;