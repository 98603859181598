import React, { useState, useEffect, useCallback } from 'react';
import { Moon, Sun, Heart, ChevronRight, Info, Filter } from 'lucide-react';
import QuestionDisplay from './components/QuestionDisplay';
import ShareButton from './components/ShareButton';
import DifficultyFilter from './components/DifficultyFilter';
import CategoryFilter from './components/CategoryFilter';
import questionsData from './data/questions.json';
import { Question } from './types';
import Icon from './icon.svg'; // Update the import path

function App() {
  const [currentQuestion, setCurrentQuestion] = useState<Question>(() => {
    const randomIndex = Math.floor(Math.random() * questionsData.questions.length);
    return questionsData.questions[randomIndex];
  });
  const [isLoading, setIsLoading] = useState(false);
  const [contentType, setContentType] = useState('QUESTIONS_FOR_CONNECTION');
  const [selectedDifficulty, setSelectedDifficulty] = useState('all');
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [theme, setTheme] = useState('light');
  const [recentlyViewed, setRecentlyViewed] = useState<Question[]>([]);
  const [showShortcuts, setShowShortcuts] = useState(false);
  const [showFilters, setShowFilters] = useState(false);

  const difficulties = [
    { id: 'all', name: 'All Difficulties' },
    { id: 'easy', name: 'Easy' },
    { id: 'medium', name: 'Medium' },
    { id: 'hard', name: 'Hard' },
  ];

  const categories = Array.from(new Set(questionsData.questions.flatMap(q => q.categories)));

  const addToRecentlyViewed = useCallback((question: Question) => {
    setRecentlyViewed(prev => {
      // Check if the question is already in the list
      const isAlreadyViewed = prev.some(q => q.id === question.id);
      if (isAlreadyViewed) {
        // If it's already in the list, move it to the front
        return [question, ...prev.filter(q => q.id !== question.id)].slice(0, 4);
      } else {
        // If it's not in the list, add it to the front
        return [question, ...prev].slice(0, 4);
      }
    });
  }, []);

  const loadNextQuestion = useCallback((type = contentType) => {
    setIsLoading(true);
    setTimeout(() => {
      const filteredQuestions = questionsData.questions.filter(q => 
        (selectedDifficulty === 'all' || q.difficulty.toLowerCase() === selectedDifficulty) &&
        q.type === type &&
        (selectedCategories.length === 0 || q.categories.some(c => selectedCategories.includes(c)))
      );
      const randomIndex = Math.floor(Math.random() * filteredQuestions.length);
      const newQuestion = filteredQuestions[randomIndex];
      
      // Add the current question to Recently Viewed before changing to the new one
      if (currentQuestion) {
        addToRecentlyViewed(currentQuestion);
      }
      
      setCurrentQuestion(newQuestion);
      setIsLoading(false);
    }, 500);
  }, [contentType, selectedDifficulty, selectedCategories, currentQuestion, addToRecentlyViewed]);

  const toggleTheme = () => {
    setTheme(prevTheme => prevTheme === 'light' ? 'dark' : 'light');
  };

  const favoriteQuestion = () => {
    alert('Question favorited!');
  };

  const handleContentTypeChange = useCallback((newType: string) => {
    // Add the current question to Recently Viewed before changing content type
    if (currentQuestion) {
      addToRecentlyViewed(currentQuestion);
    }
    setContentType(newType);
    loadNextQuestion(newType);
  }, [currentQuestion, loadNextQuestion, addToRecentlyViewed]);

  useEffect(() => {
    document.body.className = theme;
  }, [theme]);

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      switch (event.key) {
        case 'n':
          loadNextQuestion();
          break;
        case 't':
          handleContentTypeChange(contentType === 'QUESTIONS_FOR_CONNECTION' ? 'WOULD_YOU_RATHER' : 'QUESTIONS_FOR_CONNECTION');
          break;
        case 'd':
          toggleTheme();
          break;
        case '?':
          setShowShortcuts(prev => !prev);
          break;
        default:
          break;
      }
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, [loadNextQuestion, contentType, handleContentTypeChange]);

  return (
    <div className={`App ${theme} min-h-screen bg-gray-100 dark:bg-gray-900 text-gray-900 dark:text-gray-100`}>
      <div className="container mx-auto p-4 max-w-4xl">
        <header className="flex flex-col items-center mb-6">
          <div className="flex items-center mb-4">
            <img src={Icon} alt="Site Icon" className="w-8 h-8 mr-2" /> {/* Adjust the icon size */}
            <h1 className="text-3xl font-bold">Questions for Connection</h1>
          </div>
          <div className="flex items-center space-x-4">
            <button
              onClick={() => setShowFilters(!showFilters)}
              className="p-2 rounded-full bg-gray-200 dark:bg-gray-700 hover:bg-gray-300 dark:hover:bg-gray-600 transition-colors duration-200"
              aria-label="Toggle filters"
            >
              <Filter className="h-5 w-5" />
            </button>
            <button
              onClick={toggleTheme}
              className="p-2 rounded-full bg-gray-200 dark:bg-gray-700 hover:bg-gray-300 dark:hover:bg-gray-600 transition-colors duration-200"
              aria-label="Toggle theme"
            >
              {theme === 'dark' ? <Sun className="h-5 w-5" /> : <Moon className="h-5 w-5" />}
            </button>
          </div>
        </header>

        {showFilters && (
          <div className="mb-6 space-y-4">
            <DifficultyFilter 
              difficulties={difficulties}
              selectedDifficulty={selectedDifficulty}
              onDifficultyChange={setSelectedDifficulty}
            />
            <CategoryFilter
              categories={categories}
              selectedCategories={selectedCategories}
              onCategoryChange={setSelectedCategories}
            />
          </div>
        )}

        <div className="mb-6 flex justify-center">
          <div className="inline-flex items-center bg-gray-200 dark:bg-gray-700 rounded-full p-1">
            <button
              className={`py-2 px-4 rounded-full transition-all duration-300 ${
                contentType === 'QUESTIONS_FOR_CONNECTION'
                  ? 'bg-white dark:bg-gray-800 text-gray-800 dark:text-white shadow-md'
                  : 'text-gray-600 dark:text-gray-300 hover:bg-gray-300 dark:hover:bg-gray-600'
              }`}
              onClick={() => handleContentTypeChange('QUESTIONS_FOR_CONNECTION')}
            >
              Connection
            </button>
            <button
              className={`py-2 px-4 rounded-full transition-all duration-300 ${
                contentType === 'WOULD_YOU_RATHER'
                  ? 'bg-white dark:bg-gray-800 text-gray-800 dark:text-white shadow-md'
                  : 'text-gray-600 dark:text-gray-300 hover:bg-gray-300 dark:hover:bg-gray-600'
              }`}
              onClick={() => handleContentTypeChange('WOULD_YOU_RATHER')}
            >
              Would You Rather
            </button>
          </div>
        </div>

        <QuestionDisplay 
          question={currentQuestion}
          isLoading={isLoading}
        />

        <div className="flex justify-between mt-6 mb-4">
          <button
            onClick={() => loadNextQuestion()}
            disabled={isLoading}
            className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded flex items-center transition duration-300"
          >
            {isLoading ? 'Loading...' : 'Next Question'}
            <ChevronRight className="ml-2 h-5 w-5" />
          </button>
          <div>
            <ShareButton question={currentQuestion.text} />
            <button
              onClick={favoriteQuestion}
              className="p-2 rounded-full bg-gray-200 dark:bg-gray-700 hover:bg-gray-300 dark:hover:bg-gray-600 ml-2 transition-colors duration-200"
              aria-label="Favorite question"
            >
              <Heart className="h-5 w-5" />
            </button>
          </div>
        </div>

        <div className="mb-4">
          <h2 className="text-xl font-semibold mb-2">Recently Viewed</h2>
          <div className="flex space-x-2 overflow-x-auto pb-2">
            {recentlyViewed.map((item) => (
              <div key={item.id} className="bg-white dark:bg-gray-800 p-3 rounded-lg shadow-md flex-shrink-0 max-w-xs border border-gray-200 dark:border-gray-700">
                <p className="text-sm">{item.text}</p>
              </div>
            ))}
          </div>
        </div>

        <button
          onClick={() => setShowShortcuts(true)}
          className="bg-gray-200 dark:bg-gray-700 hover:bg-gray-300 dark:hover:bg-gray-600 text-gray-800 dark:text-white font-bold py-2 px-4 rounded flex items-center transition duration-300"
        >
          <Info className="mr-2 h-5 w-5" />
          Keyboard Shortcuts
        </button>

        {showShortcuts && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white dark:bg-gray-800 p-6 rounded-lg max-w-md w-full">
              <h2 className="text-2xl font-bold mb-4">Keyboard Shortcuts</h2>
              <ul className="space-y-2">
                <li><strong>N</strong>: Next Question</li>
                <li><strong>T</strong>: Toggle Question Type</li>
                <li><strong>D</strong>: Toggle Dark/Light Theme</li>
                <li><strong>?</strong>: Show/Hide Shortcuts</li>
              </ul>
              <button
                onClick={() => setShowShortcuts(false)}
                className="mt-4 bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded transition duration-300"
              >
                Close
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default App;