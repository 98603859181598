import React from 'react';
import { Share2 } from 'lucide-react';

interface ShareButtonProps {
  question: string;
}

const ShareButton: React.FC<ShareButtonProps> = ({ question }) => {
  const shareQuestion = () => {
    if (navigator.share) {
      navigator.share({
        title: 'Questions For Connections',
        text: question,
        url: window.location.href,
      }).then(() => {
        console.log('Question shared successfully');
      }).catch((error) => {
        console.log('Error sharing question:', error);
      });
    } else {
      // Fallback for browsers that don't support the Web Share API
      const shareUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(question)}&url=${encodeURIComponent(window.location.href)}`;
      window.open(shareUrl, '_blank');
    }
  };

  return (
    <button
      onClick={shareQuestion}
      className="p-2 rounded-full hover:bg-gray-200 dark:hover:bg-gray-700 mr-2"
      aria-label="Share question"
    >
      <Share2 className="h-5 w-5" />
    </button>
  );
};

export default ShareButton;